"use client";

/**
 * Third-party libraries.
 */
import {
  ApolloClient,
  ApolloProvider as ApolloProviderComponent,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { useMemo } from "react";

/**
 * Project components.
 */
import { DateUtility } from "@/components/client/date/utility";
import { useNetworkContext } from "@/components/client/network";

// Adds messages only in a dev environment
loadDevMessages();
loadErrorMessages();

/**
 * Project components.
 */

/**
 * Provides the Apollo client contexts. Put this on the top
 */
const ApolloClientProvider = ({ children }: React.PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const { setConnected, setLoading } = useNetworkContext();

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================
  /**
   * Apollo Web Socket link which specifies the GraphQL server endpoint.
   */
  const webSocketLink = useMemo(
    () =>
      typeof window === "undefined"
        ? null
        : new GraphQLWsLink(
            createClient({
              url: process.env.NEXT_PUBLIC_APOLLO_GRAPHQL_WEB_SOCKET_ENDPOINT,
              // shouldRetry: () => true,
              // keepAlive: 3000,
              on: {
                opened: () => {
                  console.log(
                    DateUtility.getDateTime({ date: new Date() }) +
                      " - Apollo GraphQL WebSocket opened."
                  );
                },
                connected: (socket) => {
                  // activeSocket = socket;
                  console.log(
                    DateUtility.getDateTime({ date: new Date() }) +
                      " - Apollo GraphQL WebSocket established.",
                    socket
                  );
                  setConnected(true);
                  setLoading(false);
                },
                closed: () => {
                  console.log(
                    DateUtility.getDateTime({ date: new Date() }) +
                      " - Apollo GraphQL WebSocket closed."
                  );
                  setConnected(false);
                  setLoading(false);
                },
                connecting: () => {
                  console.log(
                    DateUtility.getDateTime({ date: new Date() }) +
                      " - Apollo GraphQL WebSocket connecting."
                  );
                },
                error: (error) => {
                  console.log(
                    DateUtility.getDateTime({ date: new Date() }) +
                      " - Apollo GraphQL WebSocket error: ",
                    error
                  );
                },
                message: (message) => {
                  console.log(
                    DateUtility.getDateTime({ date: new Date() }) +
                      " - Apollo GraphQL WebSocket message: ",
                    message
                  );
                },
                ping: (received) => {
                  console.log(
                    DateUtility.getDateTime({ date: new Date() }) +
                      " - Apollo GraphQL WebSocket ping."
                  );
                },
                pong: () => {
                  console.log(
                    DateUtility.getDateTime({ date: new Date() }) +
                      " - Apollo GraphQL WebSocket pong."
                  );
                },
              },
            })
          ),
    [setConnected, setLoading]
  );

  /**
   * Apollo HTTP link which specifies the GraphQL server endpoint.
   */
  const httpLink = useMemo(
    () =>
      createHttpLink({
        uri: process.env.NEXT_PUBLIC_APOLLO_GRAPHQL_ENDPOINT,
        credentials: "same-origin",
      }),
    []
  );

  /**
   * Apollo Authentication link that appends the Auth0 authentication token to
   * the Graphql request header.
   */
  const authLink = useMemo(
    () =>
      setContext(async (_, { headers }) => {
        return {
          headers: {
            ...headers,
            // authorization: auth0Token ? `Bearer ${auth0Token}` : "",
          },
        };
      }),
    []
  );

  /**
   * The split function takes three parameters:
   * - A function that's called for each operation to execute
   * - The Link to use for an operation if the function returns a "truthy" value
   * - The Link to use for an operation if the function returns a "falsy" value
   *
   */
  const splitLink = useMemo(
    () =>
      webSocketLink === null
        ? httpLink
        : split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              return (
                definition.kind === "OperationDefinition" &&
                definition.operation === "subscription"
              );
            },
            webSocketLink,
            httpLink
          ),
    [httpLink, webSocketLink]
  );

  /**
   * Apollo client.
   */
  const client = useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({}),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "network-only",
          },
          mutate: {
            fetchPolicy: "network-only",
          },
          query: {
            fetchPolicy: "network-only",
          },
        },
        link: authLink.concat(splitLink),
        name: process.env.NEXT_PUBLIC_APOLLO_CLIENT_NAME,
        version: "0.0.0",
      }),
    [authLink, splitLink]
  );

  return (
    <ApolloProviderComponent client={client}>
      {children}
    </ApolloProviderComponent>
  );
};

export default ApolloClientProvider;
