/**
 * Third-party libraries.
 */
import { Button, Checkbox, Form, Input, Spin, TimePicker, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  ClockCircleOutlined as IconClockCircleOutlined,
  QuestionCircleOutlined as IconQuestionCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import dayjs from "dayjs";

import {
  DayOfWeek,
  useBusinessHoursQuery,
  useBusinessHoursUpdateMutation,
} from "../graphql";
import {
  formatDuration,
  formatTime,
} from "@/components/common/time/utilities/time-utility";

/** Type for the <Form /> component's values. */
type BusinessHoursFormValue = {
  businessHours: {
    day: DayOfWeek;
    /**
     * This is just the format that TimePicker.RangePicker normalizes into.
     *
     * Example
     *  ['9:00', '16:00']
     */
    timeRange: [string, string];
    active: boolean;
  }[];
};

type BusinessHoursFormProps = {
  /** Optionally disable the form from outside this component. @defaultValue `true` */
  enabled?: boolean;
};

export function BusinessHoursForm({ enabled = true }: BusinessHoursFormProps) {
  const [isEditing, setIsEditing] = useState(false);

  const [form] = useForm<BusinessHoursFormValue>();

  // ===========================================================================
  // Queries
  // ===========================================================================
  const businessHoursQuery = useBusinessHoursQuery();

  // ===========================================================================
  // Mutations
  // ===========================================================================
  const [businessHoursUpdate, { loading: businessHoursUpdateLoading }] =
    useBusinessHoursUpdateMutation();

  // ===========================================================================
  // Helper Functions
  // ===========================================================================
  function toggleEdit(willEdit: boolean = true) {
    if (willEdit === false) {
      form.resetFields();
      setIsEditing(false);
      return;
    }

    setIsEditing(true);

    const defaultBusinessHours = businessHoursQuery.data?.businessHours?.map(
      (businessHour) => {
        return {
          day: businessHour.day,
          timeRange: [businessHour.startTime, businessHour.endTime],
          active: businessHour.active,
        };
      }
    );

    form.setFieldsValue({
      businessHours: defaultBusinessHours,
    });
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center gap-x-2 justify-between">
        <div className="flex flex-nowrap gap-2 text-nowrap">
          <span>Business Hours</span>
          <Tooltip title="Adjust if the business is open on specific days of the week.">
            <IconQuestionCircleOutlined className="cursor-pointer" />
          </Tooltip>
        </div>

        {isEditing ? (
          <div className="flex gap-x-2">
            <Button onClick={() => toggleEdit(false)}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              loading={businessHoursUpdateLoading}
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => toggleEdit(true)}
            disabled={businessHoursQuery.loading}
          >
            Edit
          </Button>
        )}
      </div>

      <div className="h-3" />

      {businessHoursQuery.loading && (
        <div className="flex justify-center">
          <Spin />
        </div>
      )}

      <Form
        form={form}
        onFinish={async (values) => {
          const newBusinessHours = values.businessHours.map((businessHour) => {
            return {
              day: businessHour.day,
              startTime: businessHour.timeRange[0],
              endTime: businessHour.timeRange[1],
              active: businessHour.active,
            };
          });

          await businessHoursUpdate({
            variables: {
              input: {
                newBusinessHours,
              },
            },
          });

          toggleEdit(false);
        }}
      >
        {businessHoursQuery.data?.businessHours?.map((businessHour, index) => (
          <div
            key={businessHour.id}
            className="border-b py-2 flex gap-x-2 justify-between"
          >
            <div className="flex gap-x-2 items-center">
              {isEditing ? (
                <Form.Item
                  name={["businessHours", index, "active"]}
                  className="!mb-0"
                  getValueProps={(value) => ({ checked: value })}
                  getValueFromEvent={(e) => e.target.checked}
                >
                  <Checkbox />
                </Form.Item>
              ) : (
                <></>
              )}

              {isEditing ? (
                <Form.Item name={["businessHours", index, "day"]} hidden>
                  <Input value={businessHour.day} />
                </Form.Item>
              ) : (
                <></>
              )}

              <span>
                {/* Pascal Case for DayOfWeek (i.e. "MONDAY" to "Monday") */}
                {[
                  businessHour.day[0].toUpperCase(),
                  ...businessHour.day.slice(1).toLowerCase(),
                ]}
              </span>
            </div>

            <div className="flex gap-x-2 items-center">
              {isEditing ? (
                <Form.Item
                  name={["businessHours", index, "timeRange"]}
                  className="!mb-0"
                  /**
                   * Deserialization: Form Data (TS) -> Component Values (AntD)
                   * - `timeRange` - coming from the form data. e.g. "businessHours[0].timeRange": ["2024-01-01T04:00:00.000+0000", "2024-01-01T11:30:00.000+0000"]
                   *
                   * Why is this needed? `setFieldsValue()` is used to set the initial values of the form.
                   */
                  getValueProps={(timeRange: [string, string]) => {
                    const [startDate, endDate] = timeRange;
                    return {
                      // TimeRange.RangePicker needs a `dayjs()` object to work. (It uses dayjs under the hood).
                      // Otherwise, it will throw a `dateTime.isValid() is not a function` error because dateTime is not a dayjs object.
                      value: [dayjs(startDate), dayjs(endDate)],
                    };
                  }}
                  /**
                   * Serialization: Component Values (AntD) -> Form Data (TS)
                   * @param dateRange - ["2024-01-01T04:00:00.000+0000", "2024-01-01T11:30:00.000+0000"]
                   *
                   * Why is this needed?
                   * Selecting: 11pm (start) and 2am (end) will result in:
                   * 🛑 Bad: 11pm (2024-01-01) and 2am (2023-12-31).
                   * 👍 Good: 11pm (2024-01-01) and 2am (2024-01-02).
                   */
                  getValueFromEvent={(
                    dateRange: [dayjs.Dayjs, dayjs.Dayjs]
                  ) => {
                    let [startDate, endDate] = dateRange;

                    // Make sure their base dates are "2024-01-01"
                    startDate = startDate
                      .set("year", 2024)
                      .set("month", 0)
                      .set("date", 1);
                    endDate = endDate
                      .set("year", 2024)
                      .set("month", 0)
                      .set("date", 1);

                    // When endDate is after startDate (Return as is)
                    if (endDate.isAfter(startDate)) {
                      return [startDate, endDate];
                    }

                    // When endDate is before startDate (Add 1 day) i.e. 11pm (2024-01-01) -> 3am (2024-01-02)
                    return [startDate, endDate.add(1, "day")];
                  }}
                >
                  <TimePicker.RangePicker
                    format="hh:mm a"
                    placeholder={["0:00", "0:00"]}
                    className="w-52"
                    showNow
                    /**
                     * Disable `order` so we're allowed to enter `endDate` to exceeding 12am of today.
                     * BUT, we still need to make sure that `endDate` is a day after `startDate` via `getValueFromEvent`.
                     */
                    order={false}
                  />
                </Form.Item>
              ) : (
                <>
                  <span>{formatTime(businessHour.startTime)}</span>

                  <span>to</span>

                  <span>{formatTime(businessHour.endTime)}</span>

                  <Tooltip
                    title={`${formatDuration({
                      from: businessHour.startTime,
                      to: businessHour.endTime,
                      format: "readable-short",
                    })}`}
                  >
                    <IconClockCircleOutlined className="text-xs relative bottom-0.5 text-neutral-100" />
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        ))}
      </Form>
    </div>
  );
}
